import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Benefits4 from '../components/Benefits4'
import CTA4 from '../components/CTA4'
import FAQ4 from '../components/FAQ4'

function LP25() {
  return (
    <div className='App'>
        <Navbar/>
        <Hero/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  )
}

export default LP25
