import React from 'react'
import Navbar5 from '../components/Navbar5'
import Hero11 from '../components/Hero11'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP11() {
  return (
    <div className='App'>
        <Navbar5/>
        <Hero11/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP11
