import React from 'react'
import Navbar3 from '../components/Navbar3'
import Hero9 from '../components/Hero9'
import Benefits3 from '../components/Benefits3'
import CTA3 from '../components/CTA3'
import FAQ3 from '../components/FAQ3'

function LP21() {
  return (
    <div className='App'>
        <Navbar3/>
        <Hero9/>
        <Benefits3/>
        <CTA3/>
        <FAQ3/>
    </div>
  )
}

export default LP21
