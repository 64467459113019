import React from 'react'
import Navbar5 from '../components/Navbar5'
import Hero5 from '../components/Hero5'
import Benefits2 from '../components/Benefits2'
import CTA2 from '../components/CTA2'
import FAQ2 from '../components/FAQ2'


function LP17() {
  return (
    <div className='App'>
        <Navbar5/>
        <Hero5/>
        <Benefits2/>
        <CTA2/>
        <FAQ2/>
    </div>
  )
}

export default LP17
