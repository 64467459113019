import React from 'react'
import Navbar3 from '../components/Navbar3'
import Hero9 from '../components/Hero9'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP9() {
  return (
    <div className='App'>
        <Navbar3/>
        <Hero9/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP9
