import React from 'react'
import Navbar from '../components/Navbar'
import Hero7 from '../components/Hero7'
import Benefits3 from '../components/Benefits3'
import CTA3 from '../components/CTA3'
import FAQ3 from '../components/FAQ3'


function LP19() {
  return (
    <div className='App'>
        <Navbar/>
        <Hero7/>
        <Benefits3/>
        <CTA3/>
        <FAQ3/>
    </div>
  )
}

export default LP19
