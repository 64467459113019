import React from 'react'
import "../components/CTA.css"

function CTA() {
  return (
    <div className='cta'>
        <div className='cta_Content'>
            <div className='cta_Text'>
                <h1>PROTECTION EVERY HOMEOWNER NEEDS - WORRY LESS, ENJOY YOUR HOME MORE.</h1>
                <p>With a Regency Total Warranty home warranty plan, you’ll never have to deal with the stresses of home maintaince again. </p>
                <button>Get Started</button>
            </div>
            <div className='cta_Images'>
                <div className='cta_Image-1'>
                    <img src='/images/family-1.png' alt='girl'></img>
                </div>
                <div className='cta_Image-2'>
                    <img src='/images/family-2.png' alt='girl'></img>
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default CTA
