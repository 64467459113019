import React from 'react';
import Navbar4 from '../components/Navbar4';
import Hero4 from '../components/Hero4';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP28() {
  return (
    <div className='App'>
        <Navbar4/>
        <Hero4/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP28;
