import React from 'react';
import Navbar8 from '../components/Navbar8';
import Hero16 from '../components/Hero16';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP34() {
  return (
    <div className='App'>
        <Navbar8/>
        <Hero16/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP34;
