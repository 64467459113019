import React from 'react'
import Navbar5 from '../components/Navbar5'
import Hero5 from '../components/Hero5'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP5() {
  return (
    <div className='App'>
        <Navbar5/>
        <Hero5/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP5