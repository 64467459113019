import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 

import LP1 from './screens/LP1';
import LP2 from './screens/LP2';
import LP3 from './screens/LP3';
import LP4 from './screens/LP4';
import LP5 from './screens/LP5';
import LP6 from './screens/LP6';
import LP7 from './screens/LP7';
import LP8 from './screens/LP8';
import LP9 from './screens/LP9';
import LP10 from './screens/LP10';
import LP11 from './screens/LP11';
import LP12 from './screens/LP12';
import LP13 from './screens/LP13';
import LP14 from './screens/LP14';
import LP15 from './screens/LP15';
import LP16 from './screens/LP16';
import LP17 from './screens/LP17';
import LP18 from './screens/LP18';
import LP19 from './screens/LP19';
import LP20 from './screens/LP20';
import LP21 from './screens/LP21';
import LP22 from './screens/LP22';
import LP23 from './screens/LP23';
import LP24 from './screens/LP24';
import LP25 from './screens/LP25';
import LP26 from './screens/LP26';
import LP27 from './screens/LP27';
import LP28 from './screens/LP28';
import LP29 from './screens/LP29';
import LP30 from './screens/LP30';
import LP31 from './screens/LP31';
import LP32 from './screens/LP32';
import LP33 from './screens/LP33';
import LP34 from './screens/LP34';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/facebook" element={<LP1 />} />
          <Route path="/linkedin" element={<LP2 />} />
          <Route path="/klaviyo" element={<LP3 />} />
          <Route path="/google" element={<LP4 />} />
          <Route path="/taboola" element={<LP5 />} />
          <Route path="/x" element={<LP6 />} />
          <Route path="/facebook-alt" element={<LP7 />} />
          <Route path="/linkedin-alt" element={<LP8 />} />
          <Route path="/klaviyo-alt" element={<LP9 />} />
          <Route path="/google-alt" element={<LP10 />} />
          <Route path="/taboola-alt" element={<LP11 />} />
          <Route path="/x-alt" element={<LP12 />} />
          <Route path="/facebook-2" element={<LP13 />} />
          <Route path="/linkedin-2" element={<LP14 />} />
          <Route path="/klaviyo-2" element={<LP15 />} />
          <Route path="/google-2" element={<LP16 />} />
          <Route path="/taboola-2" element={<LP17 />} />
          <Route path="/x-2" element={<LP18 />} />
          <Route path="/facebook-3" element={<LP19 />} />
          <Route path="/linkedin-3" element={<LP20 />} />
          <Route path="/klaviyo-3" element={<LP21 />} />
          <Route path="/google-3" element={<LP22 />} />
          <Route path="/taboola-3" element={<LP23 />} />
          <Route path="/x-3" element={<LP24 />} />
          <Route path="/facebook-4" element={<LP25 />} />
          <Route path="/linkedin-4" element={<LP26 />} />
          <Route path="/klaviyo-4" element={<LP27 />} />
          <Route path="/google-4" element={<LP28 />} />
          <Route path="/taboola-4" element={<LP29 />} />
          <Route path="/x-4" element={<LP30 />} />
          <Route path="/bing" element={<LP31 />} />
          <Route path="/bing-alt" element={<LP32 />} />
          <Route path="/north-carolina" element={<LP33 />} />
          <Route path="/north-carolina-alt" element={<LP34 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
