import React from 'react'
import Navbar4 from '../components/Navbar3'
import Hero10 from '../components/Hero10'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP10() {
  return (
    <div className='App'>
        <Navbar4/>
        <Hero10/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP10
