import React from 'react'
import Navbar2 from '../components/Navbar2'
import Hero8 from '../components/Hero8'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP8() {
  return (
    <div className='App'>
        <Navbar2/>
        <Hero8/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP8
