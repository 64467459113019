import React from 'react'
import Navbar6 from '../components/Navbar6'
import Hero6 from '../components/Hero6'
import Benefits2 from '../components/Benefits2'
import CTA2 from '../components/CTA2'
import FAQ2 from '../components/FAQ2'


function LP18() {
  return (
    <div className='App'>
        <Navbar6/>
        <Hero6/>
        <Benefits2/>
        <CTA2/>
        <FAQ2/>
    </div>
  )
}

export default LP18
