import React from 'react';
import Navbar7 from '../components/Navbar7';
import Hero14 from '../components/Hero14';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP32() {
  return (
    <div className='App'>
        <Navbar7/>
        <Hero14/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP32;
