import React from 'react'
import Navbar3 from '../components/Navbar3'
import Hero3 from '../components/Hero3'
import Benefits2 from '../components/Benefits2'
import CTA2 from '../components/CTA2'
import FAQ2 from '../components/FAQ2'


function LP15() {
  return (
    <div className='App'>
        <Navbar3/>
        <Hero3/>
        <Benefits2/>
        <CTA2/>
        <FAQ2/>
    </div>
  )
}

export default LP15
