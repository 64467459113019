import React, { useState } from 'react';
import "../components/FAQ.css";

function FAQ2() {
    // State to keep track of which questions are open
    const [openQuestion, setOpenQuestion] = useState(null);

    // Function to toggle the open/close state of a question
    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className='faq'>
            <div className='faq_Content'>
                <h2>FAQ</h2>
                <div className='faq_Questions'>
                    {[1 ].map((_, index) => (
                        <div className='faq_Question' key={index}>
                            {/* Add onClick event to toggle question */}
                            <div className="faq_QuestionHeader" onClick={() => toggleQuestion(index)}>
                                <h2>
                                What is whole house appliance insurance, and how does it work?
                                </h2>
                                <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                                </svg>
                            </div>
                            {/* Render answer below question if question is open */}
                            {openQuestion === index && (
                                <div className="faq_Answer">
                                    <p>Whole house appliance insurance from Regency Total Warranty covers the cost of repairing major appliances in your home. If an appliance breaks down, our insurance helps with the repair expenses, so you’re not caught off guard by unexpected costs.</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Add two more questions */}
                <div className='faq_Question' key={3}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(3)}>
                        <h2>
                        What are the benefits of choosing Regency Total Warranty?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 3 && (
            <div className="faq_Answer">
            <ul>
                <li>Comprehensive Coverage: Protects a wide range of appliances in your home.</li>
                <li>Affordable Pricing: Provides peace of mind without stretching your budget.</li>
                <li>24/7 Support: Access expert help whenever you need it.</li>
                <li>Convenient App: Manage your policy and get easy support through our mobile app.</li>
            </ul>
        </div>
                    )}
                </div>
                <div className='faq_Question' key={4}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(4)}>
                        <h2>
                        How can I get a quote for Regency Total Warranty’s whole house appliance insurance?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 4 && (
                        <div className="faq_Answer">
                            <p> Click the “Get My Free Quote Now” button on our website. Complete the form to get a customized quote based on your whole house appliance insurance coverage needs.
                            </p>
                        </div>
                    )}
                </div>
                {/* <div className='faq_Question' key={5}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(5)}>
                        <h2>
                        What is the response time for a service request?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 5 && (
                        <div className="faq_Answer">
                            <p>Regency Home Warranty is committed to prompt service. A certified technician will arrive to resolve your problem within 48 hours of submitting your claim, ensuring a swift and hassle-free experience.</p>
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );
}

export default FAQ2;
