import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP1() {
  return (
    <div className='App'>
        <Navbar/>
        <Hero/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP1

// FACEBOOK