import React from 'react'
import Navbar2 from '../components/Navbar2'
import Hero8 from '../components/Hero8'
import Benefits3 from '../components/Benefits3'
import CTA3 from '../components/CTA3'
import FAQ3 from '../components/FAQ3'

function LP20() {
  return (
    <div className='App'>
        <Navbar2/>
        <Hero8/>
        <Benefits3/>
        <CTA3/>
        <FAQ3/>
    </div>
  )
}

export default LP20
