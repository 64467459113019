import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero'
import Benefits2 from '../components/Benefits2'
import CTA2 from '../components/CTA2'
import FAQ2 from '../components/FAQ2'


function LP13() {
  return (
    <div className='App'>
        <Navbar/>
        <Hero/>
        <Benefits2/>
        <CTA2/>
        <FAQ2/>
    </div>
  )
}

export default LP13
