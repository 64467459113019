import React from 'react'
import "../components/CTA.css"

function CTA3() {
  const handleClick = () => {
    window.location.href = "https://regencytotalwarranty.com/step-page/";
  };

  return (
    <div className='cta'>
        <div className='cta_Content'>
            <div className='cta_Text'>
                <h1>WORRY LESS, ENJOY YOUR HOME MORE</h1>
                <p>At Regency, we combine cutting-edge technology with around-the-clock support to offer you ultimate home protection. Our affordable plans and user-friendly app keep you covered and stress-free from the best home warranty company, so you can fully enjoy your home without worrying about unexpected repairs.</p>
                <button onClick={handleClick}>Get My Free Quote Now</button>
            </div>
            <div className='cta_Images'>
                <div className='cta_Image-1'>
                    <img src='/images/family-1.png' alt='family'></img>
                </div>
                <div className='cta_Image-2'>
                    <img src='/images/family-2.png' alt='family'></img>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CTA3
