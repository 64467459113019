import React from 'react';
import Navbar7 from '../components/Navbar7';
import Hero13 from '../components/Hero13';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP31() {
  return (
    <div className='App'>
        <Navbar7/>
        <Hero13/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP31;
