import React from 'react'
import "../components/Benefits.css"

function Benefits() {
  return (
    <div className='benefits'>
        <div className='benefits_Content'>
            <h1>THE BENEFITS OF OUR HOME WARRANTY PLANS</h1>
            <p>Regency Home Warranty turns unexpected repairs into planned solutions. When breakdowns occur, easily contact the Regency team for efficient, expert, and affordable service. Enjoy the peace of mind that comes from knowing you’re prepared.</p>
            <div className='benefits_Cards'>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                    <img src='/images/woman.png' alt='woman'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>Competitive Prices for Home Warranty Plans</p>
                        <p className='benefits_Sub'>Experience the most competitive pricing on home warranty plans. Our rates are unbeatable, ensuring that you receive exceptional value for your investment.</p>
                    </div>
                </div>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                        <img src='/images/man.png' alt='man'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>Home Warranty Plans with 24/7 Support</p>
                        <p className='benefits_Sub'>Enjoy peace of mind with our dedicated 24/7 support. Our commitment to providing the best customer service means knowledgeable representatives are available around the clock to assist you.</p>
                    </div>
                </div>
            </div>

        </div>
      
    </div>
  )
}

export default Benefits
