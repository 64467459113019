import React, { useState } from 'react';
import "../components/FAQ.css";

function FAQ() {
    // State to keep track of which questions are open
    const [openQuestion, setOpenQuestion] = useState(null);

    // Function to toggle the open/close state of a question
    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className='faq'>
            <div className='faq_Content'>
                <h2>FAQ</h2>
                <div className='faq_Questions'>
                    {[1 ].map((_, index) => (
                        <div className='faq_Question' key={index}>
                            {/* Add onClick event to toggle question */}
                            <div className="faq_QuestionHeader" onClick={() => toggleQuestion(index)}>
                                <h2>
                                What does a Regency Home Warranty cover?
                                </h2>
                                <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                                </svg>
                            </div>
                            {/* Render answer below question if question is open */}
                            {openQuestion === index && (
                                <div className="faq_Answer">
                                    <p>The Regency Home Warranty covers the repair or replacement of major home systems and appliances. The precise details will differ according to the plan that you choose. All plans include essential components like HVAC systems, plumbing, electrical, kitchen appliances, and more.</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Add two more questions */}
                <div className='faq_Question' key={3}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(3)}>
                        <h2>
                        How much does a Regency Home Warranty cost?
                        </h2>
                        <svg fill="##114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 3 && (
                        <div className="faq_Answer">
                            <p>The cost of a Regency Home Warranty depends on the plan you choose and the level of coverage you need. Our rates are competitive, offering exceptional value for a minimal yearly investment.</p>
                        </div>
                    )}
                </div>
                <div className='faq_Question' key={4}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(4)}>
                        <h2>
                        How do I submit a claim with Regency?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 4 && (
                        <div className="faq_Answer">
                            <p>Submitting a claim with Regency is easy. You can do so by phone or online, at any time of day or night. Our streamlined process ensures a quick and efficient resolution to your home maintenance needs
                            </p>
                        </div>
                    )}
                </div>
                <div className='faq_Question' key={5}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(5)}>
                        <h2>
                        What is the response time for a service request?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 5 && (
                        <div className="faq_Answer">
                            <p>Regency Home Warranty is committed to prompt service. A certified technician will arrive to resolve your problem within 48 hours of submitting your claim, ensuring a swift and hassle-free experience.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FAQ;
