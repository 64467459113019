import React from 'react';
import Navbar from '../components/Navbar';
import Hero5 from '../components/Hero5';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP29() {
  return (
    <div className='App'>
        <Navbar/>
        <Hero5/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP29;
