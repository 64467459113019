import React from 'react'
import "../components/Benefits.css"

function Benefits4() {
  return (
    <div className='benefits'>
        <div className='benefits_Content'>
            <h1>THE BENEFITS OF CHOOSING A HOME RESIDENTIAL WARRANTY
            </h1>
            <p>At Regency Total Warranty, we turn unexpected repairs into planned solutions. Contact our team for efficient, expert, and affordable service when breakdowns occur. Enjoy peace of mind knowing you are ready with our home residential warranty. Our mission is simple: to help you prepare for breakdowns without breaking the bank. With Regency, unexpected repair costs will not wreak havoc on your budget.
            </p>
            <div className='benefits_Cards'>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                    <img src='/images/woman.png' alt='woman'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>A Competitively Priced Home Residential Warranty
                        </p>
                        <p className='benefits_Sub'>At Regency, we understand that life is full of surprises, and breakdowns can happen when you least expect them. Our commitment is to provide comprehensive home residential warranty plans at unbeatable prices. We believe everyone deserves peace of mind without compromising their financial well-being. Experience exceptional value and reliable protection, ensuring unexpected repair costs will not wreak havoc on your monthly budget.
                        </p>
                    </div>
                </div>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                        <img src='/images/man.png' alt='man'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>24/7 Support For Your Home Residential Warranty
                        </p>
                        <p className='benefits_Sub'>We believe that peace of mind should be accessible to everyone at Regency Total Warranty. Our home residential warranty plans offer reliable protection at competitive prices, ensuring you're ready for unexpected repairs. With our 24/7 customer service, you can count on us to resolve issues quickly and get you back to enjoying your home with confidence.</p>
                    </div>
                </div>
            </div>

        </div>
      
    </div>
  )
}

export default Benefits4