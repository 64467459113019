import React, { useEffect } from 'react';
import "../components/Hero.css";

function Hero2() {
  useEffect(() => {
    
    if (!document.querySelector('script[src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VtmeHY"]')) {
        const script = document.createElement('script');
        script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VtmeHY';
        script.async = true;
        script.onload = () => console.log('Klaviyo script loaded successfully');
        script.onerror = (e) => console.error('Error loading Klaviyo script:', e);
        document.body.appendChild(script);
  
        return () => {
          document.body.removeChild(script);
        };
      }
    }, []);

  return (
    <div className='hero'>
      <div className='hero_Content'>
        <div className='hero_Banner'>
          <img src='/images/eletronics.png' alt='girl'></img>
          <div className='hero_Offer'>
            <p>SUMMER SALE! $300 OFF!</p>
          </div>
          <h5>GET THE BEST HOME WARRANTY PLAN</h5>
          <h6>Protect your home appliances and systems with our comprehensive coverage.</h6>
          <div className='klaviyo-form-SdqbLS'>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero2;
