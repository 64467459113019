import React from 'react'
import "../components/Benefits.css"

function Benefits3() {
  return (
    <div className='benefits'>
        <div className='benefits_Content'>
            <h1>THE BENEFITS OF CHOOSING THE BEST HOME WARRANTY COMPANY
            </h1>
            <p>Experience the ease of managing home repairs with Regency Total Warranty. We provide affordable and reliable protection, turning unexpected repairs into manageable solutions. Easily reach our expert team for fast and affordable service and enjoy peace of mind knowing you’re covered by the best home warranty company. We aim to ensure unexpected repair costs don’t disrupt your budget, giving you the confidence and security you need.
            </p>
            <div className='benefits_Cards'>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                    <img src='/images/woman.png' alt='woman'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>Competitively Priced Plans from the Best Home Warranty Company
                        </p>
                        <p className='benefits_Sub'>At Regency, we empower homeowners and car owners with affordable and reliable protection against the unexpected. Our mission as the best home warranty company is to help you be prepared for breakdowns without breaking the bank. Enjoy peace of mind with our comprehensive home and auto warranty plans, designed to offer exceptional value and safeguard your financial well-being.</p>
                    </div>
                </div>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                        <img src='/images/man.png' alt='man'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>The Best Home Warranty Company Provides 24/7 Support
                        </p>
                        <p className='benefits_Sub'>Life is full of surprises, and breakdowns can happen when you least expect them. At Regency Total Warranty, we're dedicated to providing comprehensive home warranty plans that won’t break the bank. Our 24/7 customer support means that whenever you need assistance, our friendly and knowledgeable team is just a call away, ensuring you stay worry-free and secure with the best home warranty company.</p>
                    </div>
                </div>
            </div>

        </div>
      
    </div>
  )
}

export default Benefits3