import React from 'react'
import "../components/CTA.css"

function CTA4() {
  const handleClick = () => {
    window.location.href = "https://regencytotalwarranty.com/step-page/";
  };

  return (
    <div className='cta'>
        <div className='cta_Content'>
            <div className='cta_Text'>
                <h1>WORRY LESS, ENJOY YOUR HOME MORE</h1>
                <p>Experience the perfect blend of affordability and convenience with Regency. Our advanced technology simplifies managing your home residential warranty coverage, while 24/7 support means help is always available. Enjoy peace of mind with a plan designed to make home maintenance hassle-free.</p>
                <button onClick={handleClick}>Get My Free Quote Now</button>
            </div>
            <div className='cta_Images'>
                <div className='cta_Image-1'>
                    <img src='/images/family-1.png' alt='family'></img>
                </div>
                <div className='cta_Image-2'>
                    <img src='/images/family-2.png' alt='family'></img>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CTA4
