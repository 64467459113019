import React from 'react';
import Navbar2 from '../components/Navbar2';
import Hero6 from '../components/Hero6';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP30() {
  return (
    <div className='App'>
        <Navbar2/>
        <Hero6/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP30;
