import React from 'react'
import Navbar6 from '../components/Navbar6'
import Hero6 from '../components/Hero6'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP6() {
  return (
    <div className='App'>
        <Navbar6/>
        <Hero6/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP6

// FACEBOOK ALT