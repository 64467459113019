import React, { useState } from 'react';
import "../components/FAQ.css";

function FAQ3() {
    // State to keep track of which questions are open
    const [openQuestion, setOpenQuestion] = useState(null);

    // Function to toggle the open/close state of a question
    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <div className='faq'>
            <div className='faq_Content'>
                <h2>FAQ</h2>
                <div className='faq_Questions'>
                    {[1 ].map((_, index) => (
                        <div className='faq_Question' key={index}>
                            {/* Add onClick event to toggle question */}
                            <div className="faq_QuestionHeader" onClick={() => toggleQuestion(index)}>
                                <h2>
                                What makes Regency Total Warranty the best home warranty company?
                                </h2>
                                <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                                </svg>
                            </div>
                            {/* Render answer below question if question is open */}
                            {openQuestion === index && (
                                <div className="faq_Answer">
                                    <p>Regency offers affordable, reliable protection for your home and car. We turn unexpected repairs into manageable solutions with comprehensive coverage, competitive pricing, and 24/7 support.</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Add two more questions */}
                <div className='faq_Question' key={3}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(3)}>
                        <h2>
                        How does Regency ensure affordable protection?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 3 && (
            <div className="faq_Answer">
                <p>Our competitively priced plans provide exceptional value without sacrificing coverage. As the best home warranty company, we help prepare homeowners for unexpected repairs without breaking the bank.
                </p>
        </div>
                    )}
                </div>
                <div className='faq_Question' key={4}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(4)}>
                        <h2>
                        What kind of support does Regency Total Warranty provide?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 4 && (
                        <div className="faq_Answer">
                            <p> We provide 24/7 customer support, so help is always a call away. Our team is ready to assist with any breakdowns or repairs, ensuring you stay worry-free and secure with the best home warranty company.
                            </p>
                        </div>
                    )}
                </div>
                {/* <div className='faq_Question' key={5}>
                    <div className="faq_QuestionHeader" onClick={() => toggleQuestion(5)}>
                        <h2>
                        What is the response time for a service request?
                        </h2>
                        <svg fill="#114A4D" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"/>
                        </svg>
                    </div>
                    {openQuestion === 5 && (
                        <div className="faq_Answer">
                            <p>Regency Home Warranty is committed to prompt service. A certified technician will arrive to resolve your problem within 48 hours of submitting your claim, ensuring a swift and hassle-free experience.</p>
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );
}

export default FAQ3;
