import React from 'react'
import "../components/CTA.css"

function CTA2() {
  const handleClick = () => {
    window.location.href = "https://regencytotalwarranty.com/step-page/";
  };

  return (
    <div className='cta'>
        <div className='cta_Content'>
            <div className='cta_Text'>
                <h1>WORRY LESS, ENJOY YOUR HOME MORE</h1>
                <p>With Regency, you gain peace of mind through affordable, comprehensive whole house appliance insurance. Our state-of-the-art mobile app ensures you’re always prepared and stress-free, letting you focus on enjoying your home.</p>
                <button onClick={handleClick}>Get My Free Quote Now</button>
            </div>
            <div className='cta_Images'>
                <div className='cta_Image-1'>
                    <img src='/images/family-1.png' alt='family'></img>
                </div>
                <div className='cta_Image-2'>
                    <img src='/images/family-2.png' alt='family'></img>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CTA2
