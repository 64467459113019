import React from 'react'
import Navbar4 from '../components/Navbar4'
import Hero4 from '../components/Hero4'
import Benefits2 from '../components/Benefits2'
import CTA2 from '../components/CTA2'
import FAQ2 from '../components/FAQ2'


function LP16() {
  return (
    <div className='App'>
        <Navbar4/>
        <Hero4/>
        <Benefits2/>
        <CTA2/>
        <FAQ2/>
    </div>
  )
}

export default LP16
