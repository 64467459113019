import React from 'react'
import Navbar5 from '../components/Navbar5'
import Hero11 from '../components/Hero11'
import Benefits3 from '../components/Benefits3'
import CTA3 from '../components/CTA3'
import FAQ3 from '../components/FAQ3'

function LP23() {
  return (
    <div className='App'>
        <Navbar5/>
        <Hero11/>
        <Benefits3/>
        <CTA3/>
        <FAQ3/>
    </div>
  )
}

export default LP23
