import React from 'react'
import Navbar6 from '../components/Navbar6'
import Hero12 from '../components/Hero12'
import Benefits3 from '../components/Benefits3'
import CTA3 from '../components/CTA3'
import FAQ3 from '../components/FAQ3'

function LP24() {
  return (
    <div className='App'>
        <Navbar6/>
        <Hero12/>
        <Benefits3/>
        <CTA3/>
        <FAQ3/>
    </div>
  )
}

export default LP24
