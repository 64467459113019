import React from 'react'
import "../components/Benefits.css"

function Benefits2() {
  return (
    <div className='benefits'>
        <div className='benefits_Content'>
            <h1>THE BENEFITS OF WHOLE HOUSE APPLIANCE INSURANCE</h1>
            <p>Life happens, and so do repairs. Regency Total Warranty transforms unexpected breakdowns into planned solutions. Contact our expert team for efficient and affordable service, ensuring you’re always prepared. We empower homeowners with reliable protection against the unexpected. Our mission is to help you manage repair costs without breaking the bank, providing hassle-free, comprehensive whole house appliance insurance coverage.</p>
            <div className='benefits_Cards'>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                    <img src='/images/woman.png' alt='woman'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>Competitive Prices for Home Warranty Plans</p>
                        <p className='benefits_Sub'>At Regency, we believe peace of mind should be affordable for everyone. Our whole house appliance insurance plans provide reliable protection at competitive prices, ensuring you expect unexpected repairs. We are committed to delivering exceptional value and comprehensive coverage so you can focus on living life without worrying about unforeseen appliance repair costs.</p>
                    </div>
                </div>
                <div className='benefits_Card'>
                    <div className='benefits_Image'>
                        <img src='/images/man.png' alt='man'></img>
                    </div>
                    <div className='benefits_Text'>
                        <p className='benefits_Heading'>Whole House Appliance Insurance with 24/7 Support</p>
                        <p className='benefits_Sub'>Regency Total Warranty is committed to offering affordable peace of mind through our comprehensive whole house appliance insurance plans. Our competitive prices guarantee protection against unexpected repairs without straining your budget. Plus, with 24/7 customer service, you’ll have access to expert help whenever needed, ensuring you’re always prepared and at ease.</p>
                    </div>
                </div>
            </div>

        </div>
      
    </div>
  )
}

export default Benefits2

