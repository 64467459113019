import React from 'react';
import Navbar3 from '../components/Navbar3';
import Hero3 from '../components/Hero3';
import Benefits4 from '../components/Benefits4';
import CTA4 from '../components/CTA4';
import FAQ4 from '../components/FAQ4';

function LP27() {
  return (
    <div className='App'>
        <Navbar3/>
        <Hero3/>
        <Benefits4/>
        <CTA4/>
        <FAQ4/>
    </div>
  );
}

export default LP27;
