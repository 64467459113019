import React from 'react'
import Navbar3 from '../components/Navbar3'
import Hero3 from '../components/Hero3'
import Benefits from '../components/Benefits'
import CTA from '../components/CTA'
import FAQ from '../components/FAQ'

function LP3() {
  return (
    <div className='App'>
        <Navbar3/>
        <Hero3/>
        <Benefits/>
        <CTA/>
        <FAQ/>
    </div>
  )
}

export default LP3